import * as React from 'react'
import { Redirect } from '@reach/router'
import { useRecoilValue } from 'recoil'
// import { Helmet } from 'react-helmet'
import AgeGate from '@/components/AgeGate'
import { hasValidAgeManager } from '@/recoil/customer'
import { marketCodeManager } from '@/recoil/marketCode'

const IndexPage = () => {
  const hasValidAge = useRecoilValue(hasValidAgeManager)
  const marketCode = useRecoilValue(marketCodeManager)

  return (
    <>
      {hasValidAge
        ? <Redirect noThrow to={ `/${marketCode}` } />
        : <AgeGate />}
    </>
  )
}

export default IndexPage
